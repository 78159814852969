//import { trigger, state, style, transition, animate } from '@angular/core';

import {
  animate,
  group,
  keyframes,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const listItemAnimation = trigger('listItemAnimation', [
  state('in', style({ transform: 'opacity: 1' })),

  transition('void => *', [
    style({ transform: 'translateY(0)', opacity: 0 }),
    animate('1000ms ease-in'),
  ]),
  transition('* => void', [
    animate(
      '300ms ease-out',
      style({ transform: 'scaleY(0) translateY(200px)' })
    ),
  ]),
]);

export const leftSlideInAnimation = trigger('leftSlideIn', [
  // Transition between any two states
  transition('* <=> *', [
    // Events to apply
    // Defined style and animation function to apply
    // Config object with optional set to true to handle when element not yet added to the DOM
    query(
      ':enter, :leave',
      style({ position: 'fixed', width: '100%', zIndex: 2 }),
      { optional: true }
    ),
    // group block executes in parallel
    group([
      query(
        ':enter',
        [
          style({ transform: 'translateX(100%)' }),
          animate('0.5s ease-out', style({ transform: 'translateX(0%)' })),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({ transform: 'translateX(0%)' }),
          animate('0.5s ease-out', style({ transform: 'translateX(-100%)' })),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);

export const bounceAnimation = trigger('lightBounce', [
  state('in', style({ transform: 'opacity: 1' })),

  transition('* => in', [
    style({ transform: 'translateY(0)', opacity: 1 }),
    animate(
      800,
      keyframes([
        style({ transform: 'translateY(0)', offset: 0 }),
        style({ transform: 'translateY(-4px)', offset: 0.4 }),
        style({ transform: 'translateY(0)', offset: 0.6 }),
        style({ transform: 'translateY(-2px)', offset: 0.8 }),
        style({ transform: 'translateY(0)', offset: 1 }),
      ])
    ),
  ]),
]);

export const flipAnimation = trigger('flip', [
  state(
    'active',
    style({
      transform: 'rotateY(179deg)',
    })
  ),
  state(
    'inactive',
    style({
      transform: 'rotateY(0)',
    })
  ),
]);

export const fadeinAnimation = trigger('fadein', [
  transition('* => *', [
    style({ opacity: 0 }),
    animate(1000, style({opacity: 1}))
  ])
]);

export const blobAnimation = trigger('blob', [
  transition('* => *', [
  style({ transform: 'scale(1)', opacity: 1 }),
    animate(
      500,
      keyframes([
        style({ transform: 'scale(1)', offset: 0 }),
        style({ transform: 'scale(1.2)', offset: 0.4 }),
        style({ transform: 'scale(1.4)', offset: 0.6 }),
        style({ transform: 'scale(1.2)', offset: 0.8 }),
        style({ transform: 'scale(1)', offset: 1 })
      ]))
    ])
]);

export const zoomInAnimation = trigger('zoomIn', [
  state(
    'inactive',
    style({
      'font-size': '1.5em',
      transform: 'scale(1)',
    })
  ),
  state(
    'active',
    style({
      'letter-spacing': '0.1em',
      'font-size': '1.5em',
      'text-shadow':
        '1px 1px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 1px 2px 0 grey, 5px 13px 15px black',
      cursor: 'default',
      transform: 'scale(1.5)',
    })
  ),
  transition('inactive => active', animate('400ms ease-in')),
  transition('active => inactive', animate('300ms ease-out')),
]);

export const glowAnimation = trigger('glow', [
  state(
    'true',
    style({
      opacity: 1,
      'font-family': 'sans-serif',
      'letter-spacing': '10px',
    })
  ),

  state(
    'false',
    style({
      opacity: 0.2,
      'font-family': 'sans-serif',
      'letter-spacing': '10px',
    })
  ),

  transition('true <=> false', animate('1300ms ease-in-out')),
]);

export const cubeAnimation = trigger('rotate', [
  state(
    'right',
    style({
      transform: 'rotateY(-90deg)'
    })
  ),
  state(
    'left',
    style({
      transform: 'rotateY(90deg)'
    })
  ),
  state(
    'back',
    style({
      transform: 'rotateY(180deg)'
    })
  ),
  state(
    'top',
    style({
      transform: 'rotateX(-90deg)'
    })
  ),
  state(
    'bottom',
    style({
      transform: 'rotateX(90deg)'
    })
  ),
]);
