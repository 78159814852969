import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { AnimatedButtonComponent } from './controls/animated-button/animated-button.component';
import { GlowingHeaderComponent } from './Controls/glowing-header/glowing-header.component';
import { FlipCardComponent } from './Controls/flip-card/flip-card.component';
import { CubeComponent } from './controls/cube/cube.component';
import { SlicedGalleryComponent } from './Controls/sliced-gallery/sliced-gallery.component';
import { CardGalleryComponent } from './controls/card-gallery/card-gallery.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    NgbModule
  ],
  exports:[
    CommonModule,
    FormsModule,
    MaterialModule,
    GlowingHeaderComponent,
    FlipCardComponent,
    FlexLayoutModule,
    AnimatedButtonComponent,
    SlicedGalleryComponent,
    CardGalleryComponent,
    CubeComponent
  ],
  declarations: [
    AnimatedButtonComponent,
    GlowingHeaderComponent,
    FlipCardComponent,
    CubeComponent,
    SlicedGalleryComponent,
    CardGalleryComponent
  ]
})
export class SharedModule { }
