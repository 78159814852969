import { state, style, trigger } from '@angular/animations';
import { Component, Input} from '@angular/core';
import { flipAnimation } from '../../animations/app-animation';

@Component({
  selector: 'app-flip-card',
  templateUrl: './flip-card.component.html',
  styleUrls: ['./flip-card.component.scss'],
  animations: [flipAnimation]
})
export class FlipCardComponent {

  // #region fields and properties
  @Input() front: string = 'https://mdbootstrap.com/img/new/slides/017.jpg';
  @Input() back: string= 'test back';

  @Input() width: string = '40px';
  @Input() height: string = '40px';
  // #endregion

  constructor(){}

  // #region helper methods

  // #endregion

  // #region interface implementation

  // #endregion



}
