<div class="flip-card" [style.width] = 'width' [style.height] = 'height'>
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <img [src]='front'/>
    </div>
    <div class="flip-card-back">
      <h1>Category A</h1>
      <p class="text-wrap">{{back}}</p>
    </div>
  </div>
</div>
