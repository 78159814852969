import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './admin/about/about.component';
import { SigninRedirectCallbackComponent } from './admin/common/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './admin/common/signout-redirect-callback.component';
import { ContactUsComponent } from './admin/contact-us/contact-us.component';
import { OurServicesComponent } from './admin/our-services/our-services.component';
import { LoginGuard } from './Guards/login.guard';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { UnauthorizedComponent } from './shared/unauthorized.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path :'contact', component: ContactUsComponent},
  {path :'services',component:OurServicesComponent},

  {
    path: 'products',
    
    //canActivate: [AuthGuard],
    //data: {preload : true},
    loadChildren: () =>
      import('./products/product.module').then(m => m.ProductModule)
  },
  {
    path: 'sales-orders',
    //canActivate: [AuthGuard],
    //data: {preload : true},
    loadChildren: () =>
      import('./orders/order.module').then(m => m.OrderModule)
  },
  {
    path: 'users',
    //  canActivate: [LoginGuard],
    //data: {preload : true},
    loadChildren: () =>
      import('./admin/admin.module').then(m => m.AdminModule)
  },
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
