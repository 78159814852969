<button *ngIf="!isFab" mat-raised-button (click)="notifyParent()" [color]='color' [@lightBounce]="state" (mouseover)="state = 'in'"
  (mouseout)="state = 'out'">
  <mat-icon *ngIf="alignLeft">{{icon}}</mat-icon>
  {{content}}
  <mat-icon *ngIf="alignRight">{{icon}}</mat-icon>
</button>
<button *ngIf="isFab" mat-mini-fab (click)="notifyParent()" [color]='color' [@lightBounce]="state" (mouseover)="state = 'in'"
  (mouseout)="state = 'out'">
  <mat-icon *ngIf="alignLeft">{{icon}}</mat-icon>
  {{content}}
  <mat-icon *ngIf="alignRight">{{icon}}</mat-icon>
</button>
