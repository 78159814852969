<div class="container mt-3">
    <div class="row featurette">
    <div class="col-md-7">
      <h2 class="featurette-heading" >About Navsa</h2>
      <p class="lead text-justify">Navsa International is an up  and coming Delivered Wholesaler based in Milton Keynes We are currently trading very well based on our excellent customer and supplier relationships. The business operates a delivery service to wholesalers and retailers alike, as well as customised solutions for customers Throughout the UK.
Operating out of a 15,000 sq foot site based in Milton Keynes with ideal transport links to the M1 motorway.
        </p>
    </div>
    <div class="col-md-5">
      <!-- <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee"/><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg> -->
      <img src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/aboutus.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500">
    </div>
  </div>

  <hr class="featurette-divider">

  <div class="row featurette">
    <div class="col-md-7 order-md-2">
      <h2 class="featurette-heading">Our Vision </h2>
      <p class="lead text-justify">We aim to be the first choice delivered wholesaler to the independent retail trade throughout the UK and offer a comprehensive range of products at very competitive prices including groceries, confectionery, soft drinks, toiletries, baby products, household cleaning products and paper goods.
        We strive to provide the best value for money proposition in combination with latest technology for ease of ordering to our independent retailers so they can have the best deals delivered at the click of a button.
        </p>
    </div>
    <div class="col-md-5 order-md-1">
      <!-- <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee"/><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text></svg> -->
      <img src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/Vision.jpg" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500">

    </div>
  </div>

  <hr class="featurette-divider">

  <!-- <div class="row featurette d-flex">
    <div class="justify-content-center align-items-center">Why?</div>
    <div class="col-md-7">
      <h2 class="featurette-heading">And lastly, this one. <span class="text-muted">Our history.</span></h2>
      <p class="lead">Tarff Valley Agricultural Co-operative Society Ltd was instituted in 1903 by Mr Walter Montgomerie Neilson of Queenshill, Ringford. As the local laird, he saw financial advantages for his tenant-farmers in forming a co-operative to buy supplies of coal and lime, to be delivered by rail to Tarff Station on the Castle Douglas – Kirkcudbright branch line.

        Without Mr Neilson’s financial input during those early years the Society would have struggled to exist. For many years he paid the secretary’s salary of £50, acted as guarantor at the Bank for £200, sold three houses he owned in Ringford at a knockdown price to the Society and finally, when he died in 1951 he bequeathed £1,000 to the Society; the first agricultural co-operative in Scotland.</p>
    </div>
    <div class="col-md-5 ">
       <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label=" Why?" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#eee"/><text x="50%" y="50%" fill="#aaa" dy=".3em">Why?</text></svg> -->
      <!-- <div class="why" width="500px" height="500px" >
       <div  x="50%" y="50%"  dy=".3em"> Why?</div>
    </div>
  </div>

  <hr class="featurette-divider"> -->
 <div class="row justify-content-center why">
Why Choose Navsa?
 </div>
 <div class="row mt-5  mx-auto ">
 <div class="col-12 col-md-6 title">High Quality Products
  <div class="text text-justify">We only sell high quality British food and drink products such as: Swizzels, Hersheys, Reeses, Ritter Sport, Coca Cola, Cadburys, Mentos, Werthers, Pom Bears, Mccoys, Vita, Coco, Ferrero Rocher, Kinder, Evian, Pepsi, Tango, Kraft, Heinz, Weetabix, Unilever, Jacobs, Douwe Egberts, Pataks, Yorkshire Tea, Seabrook Crisps, Vimto, Mcvities, Mars/Snickers/Bounty, Typhoo, Red Bull, Nature Valley, Nestle, Kitkat, Haribo, Skittles, Wrigleys, and many more. </div>
 </div>
 <div class="col-12 col-md-6 title">
  Wide Range of Goods

  <div class="text">The main range of our business activity is distribution of FMCG products: confectionery, beverages, baby products, toiletries, household chemicals, cosmetics, pet-food, and building materials. We supply distributors and wholesalers all over the world with branded products from well-known producers such as: P&G, Henkel, Reckitt Benckiser, Kimberly-Clark, Unilever, Cusson, Cadbury Wedel, Ferrero, Kraft Food, Unilewer, Bestfood, Masterfood, Nestle and renowned Polish companies: Bella and York.</div>
 </div>
 <div class="col-12 col-md-6 title ">Express Deliveries
  <div class="text"> We cooperate with leading carriers and have our own fleet consisting of dozens of modern trucks. That is why, you can be certain that you will receive the ordered goods, on time, to any place throughout the UK</div>
 </div>
 <div class="col-12 col-md-6 title">Professional Service
  <div class="text ">We offer friendly and professional service and counselling of qualified team of multi-language traders. We win over trading partners with our reliability, quality, flexibility, and fast service.</div>
 </div>
 <div class="col-12 col-md-6 title ">Competitive Prices & Offers
  <div class="text">Our proactive team constantly monitor the market to make sure our prices are always competitive for our customers. Please visit our products section and check all the current offers or simply ask for them!!</div>
 </div>
 <div class="col-12 col-md-6 title">Responsive Quality
  <div class="text">Our Website is perfectly adapted to the new generation of devices, so you can make purchases using your smartphone and other mobile devices</div>
 </div>
</div>

</div>
  <!-- /END THE FEATURETTES -->

<!-- /.container -->
