<div class="container">
    <div class="row mt-5">
        <div class="col-12 col-lg-3 col-md-6">
            <img class="w-100"  src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic1.jpg">
            <p class="title text-center mt-2">FULL LOADS</p>

                <ul class="text p-0">
                    <li class="m-2" >We Can source and supply up to full loads from leading brands. </li>
                    <li class="m-2">Strong relations with Manufacturers. </li>
                    <li class="m-2">Excellent connections and stock holding on floor for fast moving lines. </li>
                </ul>

        </div>
        <div class="col-12 col-lg-3 col-md-6">
            <img  class="w-100" src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic2.jpg">
            <p class="title text-center mt-2">STORE DELIVERIES</p>
            <ul class="text p-0">
                <li class="m-2" >We Deliver throughout the UK on pallets.</li>
                <li class="m-2">Our Min order is £2k per order. </li>
            </ul>
        </div>
        <div class="col-12 col-lg-3 col-md-6">
            <img class="w-100" src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic3.jpg">
            <p class="title text-center mt-2">ROAD FREIGHT</p>
            <ul class="text p-0">
                <li class="m-2" >We can arrange UK or European haulage at low cost.</li>
                <li class="m-2">Economy and Express options.</li>
                <li class="m-2">Temperature-controlled vehicles available.</li>
            </ul>
        </div>
        <div class="col-12 col-lg-3 col-md-6">
        <img class="w-100" src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic4.jpg">
        <p class="title text-center mt-2">DOCUMENTS</p>
        <ul class="text p-0">
            <span class="text">We can provide:</span>
            <li class="m-2" >Health Certificates.</li>
            <li class="m-2">Certificates of Origin and Free Sale. </li>
            <li class="m-2">Advice and support in meeting regulations. </li>
        </ul>
    </div>
    </div>
    <div class="row mt-5">
        <div class="col-12 col-lg-3 col-md-6">
            <img class="w-100"  src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic5.jpg">
            <p class="title text-center mt-2">LABELLING</p>

                <ul class="text p-0">
                    <li class="m-2" >Products can be prepared in compliance with regulations, accounting for prohibited ingredients.</li>
                    <li class="m-2">We can add Production and Best Before dates. </li>
                    <li class="m-2">Translation and printing services available. </li>
                </ul>

        </div>
        <div class="col-12 col-lg-3 col-md-6">
            <img  class="w-100" src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic6.jpg">
            <p class="title text-center mt-2">PICKING & PACKING</p>
            <ul class="text p-0">
                <li class="m-2" >We can assist with:

                    - Sorting and labelling

                    - Loading and unloading vehicles , re-boxing and re-labelling, sorting mixed cargo and returns handling</li>
                <li class="m-2">Temporary cargo storage available. </li>
            </ul>
        </div>
        <div class="col-12 col-lg-3 col-md-6">
            <img class="w-100" src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic7.jpg">
            <p class="title text-center mt-2">STORAGE</p>
            <ul class="text p-0">
                <li class="m-2" >15,000 sq. ft. of modern warehouse storage.</li>
                <li class="m-2">Stock control.</li>
                <li class="m-2"> Shrink wrapping.</li>
            </ul>
        </div>
        <div class="col-12 col-lg-3 col-md-6">
        <img class="w-100" src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/pic8.jpg">
        <p class="title text-center mt-2"> RECEIPT HANDLING & DISPATCH</p>
        <ul class="text p-0">
            <li class="m-2" > We have expertise in goods handling:
                - Unloading
                - Checking
                - Storage
                - Reloading</li>
            <li class="m-2">Stock identification and checking from product labels or consignment notes.</li>
        </ul>
    </div>
    </div>
</div>
