import { Component, Input} from '@angular/core';
import { glowAnimation } from '../../animations/app-animation';

@Component({
  selector: 'app-glowing-header',
  templateUrl: './glowing-header.component.html',
  styleUrls: ['./glowing-header.component.scss'],
  animations: [glowAnimation]
})
export class GlowingHeaderComponent {

  // #region fields and properties
  @Input() headerText: string

  glowState: boolean = false;
  // #endregion

  constructor(){}

  // #region helper methods

  // #endregion

  // #region interface implementation

  // #endregion

}
