import { Component, Input, OnInit } from '@angular/core';
import { cubeAnimation } from '../../animations/app-animation';

@Component({
  selector: 'app-cube',
  templateUrl: './cube.component.html',
  styleUrls: ['./cube.component.scss'],
  animations: [cubeAnimation],
})
export class CubeComponent {
  // #region fields and properties
  frontImage: string;
  topImage: string;
  bottomImage: string;
  leftImage: string;
  rightImage: string;
  backImage: string;

  cubeState: string = '';

  _facingImage: string;
  get facingImage(): string {
    return this._facingImage;
  }

  @Input() set facingImage(value: string) {
    this._facingImage = value;
    this.rotate(value);
  }
  // #endregion

  constructor() {}

  // #region helper methods
  rotate(img: string) {
    switch (this.cubeState) {
      case 'front':
        this.topImage = img;

        this.cubeState = 'top';
        break;
      case 'top':
        this.bottomImage = img;

        this.cubeState = 'bottom';
        break;
      case 'bottom':
        this.leftImage = img;

        this.cubeState = 'left';
        break;
      case 'left':
        this.rightImage = img;

        this.cubeState = 'right';
        break;
      case 'right':
        this.backImage = img;

        this.cubeState = 'back';
        break;
      case 'back':
        this.frontImage = img;

        this.cubeState = 'front';
        break;
      default:
        this.frontImage = img;
        this.topImage = img;
        this.bottomImage = img;
        this.leftImage = img;
        this.rightImage = img;
        this.backImage = img;

        this.cubeState = 'front';
        break;
    }
  }
  // #endregion

  // #region interface implementation

  // #endregion
}
