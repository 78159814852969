import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  userTitles: any[] = [
    { value: 'Mr', },
    { value:'Mrs'}
  ]



  userEmail: string = "";
  companyName: string = "";
  userName: string = "";
  userTitle: string = "";
  userPhone: string = "";
  userAdress: string = "";
  userMessage: string = "";

  contactOverlay = false;

  cancel() {
    this.contactOverlay = false;
  }
  getContact(e:Event) {
    console.log(e);
    this.contactOverlay = true;
    emailjs.sendForm('service_yunnxaw', 'template_pmdcq16', e.target as HTMLFormElement, 'l092GfQ5I1QhuabJM')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  constructor() { }

  ngOnInit(): void {
  }

}
