<div class="h-100">
  <mat-sidenav-container
    class="app-sidenav-container"
    [class.dark-theme]="isDarkTheme"
  >
    <mat-sidenav #sidenav class="app-sidenav" mode="over">
      <mat-toolbar color="primary">
        <button
          *ngIf="!sidenav.opened"
          mat-icon-button
          color="accent"
          class="sidenav-toggle"
          (click)="sidenav.toggle()"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <a [routerLink]="['/home']">
          <mat-icon
            *ngIf="sidenav.opened"
            (click)="sidenav.toggle()"
            style="cursor: pointer"
            >home</mat-icon
          >
        </a>
      </mat-toolbar>
      <mat-nav-list>
        <div class="container">
          <div *ngIf="productCategories$ | async as categories">
            <button
              class="ml-2"
              mat-button
              color="primary"
              [routerLink]="['/products']"
              (click)="sidenav.toggle()"
            >
              <mat-icon>view_stream</mat-icon>
              All Products
            </button>
            <div class="m-2" *ngFor="let category of categories">
              <div *ngIf="!category.isSubGroup">
                <span (click)="sidenav.toggle()">
                  <button
                    mat-button
                    color="primary"
                    (click)="navigate(category)"
                  >
                    <mat-icon>view_stream</mat-icon>
                    {{ category.groupName }}
                  </button>
                </span>
                <div *ngFor="let subCategory of categories">
                  <div
                    class="ml-3"
                    *ngIf="
                      subCategory.isSubGroup &&
                      subCategory.groupCode === category.groupCode
                    "
                  >
                    <span (click)="sidenav.toggle()">
                      <button mat-button (click)="navigate(subCategory)">
                        <mat-icon>more_horiz</mat-icon>
                        {{ subCategory.groupName }}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <div class="app-sidenav-content">
      <mat-toolbar color="primary">
        <mat-toolbar-row>
          <button
            mat-icon-button
            color="accent"
            class="sidenav-toggle"
            (click)="sidenav.toggle()"
          >
            <mat-icon>menu</mat-icon>
          </button>
          <a routerLink="/home">
            <img
              src="https://angprod10.uksouth.cloudapp.azure.com:6005/siteimages/NavsaLogo.jpg"
              width="200px"
          /></a>
          <article class="navbar-center">
            <button mat-button color="accent" (click)="sidenav.toggle()">
              <div
                [@zoomIn]="zoomState"
                (mouseover)="zoomState = 'active'"
                (mouseleave)="zoomState = 'inactive'"
              >
                Order Now
              </div>
            </button>
          </article>

          <div
            class="nav-item ml-lg-auto"
            display="dynamic"
            *ngIf="!isLoggedIn"
          >
            <button mat-button [matMenuTriggerFor]="menu">
              <span class="material-icons">account_circle</span>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="login()">
                Login to my account
              </button>
              <button mat-menu-item (click)="register()">Register</button>
            </mat-menu>
          </div>

          <div
            *ngIf="isLoggedIn"
            class="nav-item float-end"
            ngbDropdown
            display="dynamic"
          >
            <button mat-button [matMenuTriggerFor]="menu">
              Welcome {{ userName }}
              <span class="material-icons">sentiment_satisfied_alt</span>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/users']">Account</button>
              <button mat-menu-item href="#" (click)="logout()">Logout</button>
            </mat-menu>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="wrapper">
        <router-outlet></router-outlet>
        <!-- <div [@leftSlideIn]="o.isActivated ? o.activatedRoute : ''">
          <router-outlet #o="outlet"></router-outlet>
        </div> -->
        <!-- FOOTER -->
        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6  footer-left-content">
                <p class="float-end mx-2" ><a href="#" class="back">Back to top</a></p>
                <p class="mt-3 mx-1 copyright">
                  &copy; {{ getCurrentYear() }} - Navsa International Limited - All Rights Reserved
                </p>
                <p class="mt-3">
                   <a routerLink="/about" class="mx-2">About Us</a> 
                  <a routerLink="/services" class="mx-2">Our Services</a>
                  <a routerLink="/contact"class="mx-2">Contact Us</a> 
                  <a
                    href="https://angprod10.uksouth.cloudapp.azure.com:6005/tc/TermsConditions.pdf"
                    target="_blank" class="mx-2">Terms and conditions</a
                  >
                </p>
              </div>
              <div class="col-md-6 d-flex justify-content-end footer-right-content">
                <ul class="list-inline list-unstyled d-flex">
                  <li class="mr-3">
                    <a
                      href="https://www.facebook.com/Navsa-International-Limited-106313914663560/"
                      ><img
                        src="../assets/footer/Vector (1).svg"
                        width="40px"
                    /></a>
                  </li>
                  <li class="mr-3">
                    <a href="https://twitter.com/navsaintl"
                      ><img
                        src="../assets/footer/Vector.svg"
                        width="40px"
                    /></a>
                  </li>
                  <li class="mr-3">
                    <a
                      href="https://www.linkedin.com/company/navsa-international-limited"
                      ><img
                        src="../assets/footer/Vector (2).svg"
                        width="40px"
                    /></a>
                  </li>
                  <li class="mr-3">
                    <a href="https://www.instagram.com/navsainternational/"
                      ><img
                        src="../assets/footer/Vector (3).svg"
                        width="40px"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </mat-sidenav-container>
</div>
