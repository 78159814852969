<section>
  <div class="container py-5 px-4 px-lg-0">
    <div class="head-container mx-auto d-flex row">
      <h2 class="section-head m-auto">We would love to hear from you</h2>
    </div>
    <div class="row mt-5 shadow contact-data position-relative">
      <!-- contactside -->
      <div class="col-lg-7 col-12 py-lg-5 p-4 contact-form-container">
        <h4 class="form-head mt-1 mt-lg-4">Send us an enquiry</h4>
        <!-- the contact us form -->
        <form
          name="form"
          #contactForm="ngForm"
          (ngSubmit)="getContact($event)"
          class="contact-form ps-2 w-100 mt-4"
        >
          <!-- Company Input -->
          <div class="company-container w-100 mb-3 ">
            <input
              type="text"
              name="company-name"
              placeholder="Full Name"
              class="border-bottom form-control bg-transparent py-2 px-0 border-0 rounded-0 shadow-none w-100"
              [(ngModel)]="companyName"
              #userCompanyName="ngModel"
            />
          </div>
          <!-- Name Input -->
          <div class="name-container  w-100 ">

            <div class=" px-lg-0 ">
              <input
                type="text"
                name="user-name"
                placeholder=" Business Name "
                class="border-bottom form-control bg-transparent py-2 border-0 px-0 rounded-0 shadow-none"
                [(ngModel)]="userName"
                required
                #myUserName="ngModel"
                minlength="3"
                maxlength="10"
              />
            </div>
            <small
              class="mb-0 error-msg text-danger col-10 ms-auto p-0"
              [ngClass]="{ visible: myUserName.touched && myUserName.errors }"
            >
              ENTER NAME FROM 3 TO 10 CHARACTERS PLEASE
            </small>
          </div>
          <!-- Phone Input -->
          <div class="phone-container w-100 ">
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              class="border-bottom form-control bg-transparent py-2 border-0 px-0 rounded-0 shadow-none"
              [(ngModel)]="userPhone"
              required
              #myPhone="ngModel"

            />
            <small
              class="mb-0 error-msg text-danger p-0"
              [ngClass]="{ visible: myPhone.touched && myPhone.errors }"
            >
              ENTER A VALID PHONE NUMBER PLEASE
            </small>
          </div>
          <!-- Email Input -->
          <div class="email-container w-100 ">
            <input
              type="email"
              name="email"
              placeholder="Email Adress"
              class="border-bottom form-control bg-transparent py-2 border-0 px-0 rounded-0 shadow-none"
              [(ngModel)]="userEmail"
              required
              #myEmail="ngModel"
              email
            />
            <small
              class="mb-0 error-msg text-danger p-0"
              [ngClass]="{ visible: myEmail.touched && myEmail.errors }"
            >
              ENTER A VALID E-MAIL PLEASE
            </small>
          </div>
          <!-- adress Input -->
          <!-- <div class="adress-container mb-3 w-100 ">
            <input
              type="text"
              name="adress"
              placeholder="From"
              class="border-bottom form-control bg-transparent py-2 border-0 px-0 rounded-0 shadow-none"
              [(ngModel)]="userAdress"
              #myAdress="ngModel"
            />
          </div> -->
          <!-- message  -->
          <div class="adress-container mb-3 w-100 ">
            <textarea
              name="message"
              placeholder="How can we help?"
              class="border-bottom form-control bg-transparent py-2 border-0 px-0 rounded-0 shadow-none"
              [(ngModel)]="userMessage"
              #myMessage="ngModel"
              rows="4"
            ></textarea>
          </div>
          <!-- submit btn -->
          <div class="btn-container row w-100">
            <button
              type="submit"
              class="send-btn p-2 mt-5 mb-3 text-white rounded-pill w-100 border-0"
              [disabled]="myUserName.errors || myEmail.errors || myPhone.errors"
            >
              Send Message
            </button>
          </div>
        </form>

        <!-- overlay -->
        <div
          class="contact-success position-absolute p-3 bg-white"
          *ngIf="contactOverlay"
        >
          <button
            class="x-btn d-block ms-auto me-5 mt-3 bg-transparent border-0"
            (click)="cancel()"
          >
            X
          </button>

          <p class="contact-success-text text-center">
              There is always a member of staff ready to take a call or respond to your email or enquiry. We will respond as soon as we can. Thank you for getting in touch with Navsa.
          </p>
          <!-- <p class="contact-success-text text-center">
            <b>
              In case of emergency, please call: <br />
              Name: Mrs. Sarah Saleh<br />
              Mobile: <span class="blue-text"> +2 0100800 8815 </span>
            </b>
          </p> -->
        </div>
      </div>
      <!-- info side -->
      <div class="col-lg-5 col-12 p-lg-5 p-4 contact-info position-relative">
        <h4 class="info-heder my-lg-4 my-1 text-white">Ways to get in touch</h4>
        <!-- <p class="mb-lg-5 mb-4 pe-5 pe-lg-0">
          Fill up the form and our team will get back to you within 24 hours
        </p> -->
        <!-- email details -->
        <div class="d-flex align-items-center mb-4">
          <span class="">
              <img  width="35px" src="https://img.icons8.com/external-icongeek26-outline-colour-icongeek26/64/000000/external-phone-essentials-icongeek26-outline-colour-icongeek26.png"/>



          </span>
          <div class="mial-desc ps-4">
            <h6 class="info-text mb-0 fw-bold">Call Us By Phone : +44 190809160</h6>
            <h6 class="info-text mb-0 fw-bold">Text Us : +44 7985781627</h6>
            <h6 class="info-text mb-0 fw-bold">whatsapp : +44 7544359507</h6>

            <!-- <a
              target="_blank"
              href="mailto:mice@manitontours.com"
              class="info-link text-decoration-none"
            >
              mice@manitontours.com
            </a> -->
          </div>
        </div>
        <hr>

        <div class="d-flex mb-4">
          <span>
              <img width="35px" src="https://img.icons8.com/plasticine/100/000000/apple-mail.png"/>
          </span>
          <div class="location-desc mb-lg-0 mb-5 ps-4">
            <h5 class="info-text mb-0 fw-bold">Email Us : info@navsainternational.com </h5>

          </div>
        </div>
        <hr>
        <!-- social media links -->
        <span class="info-text">
          Opening Hours
      </span>
          <div class="d-flex mb-4">

              <div class="location-desc mb-lg-0 mb-5 ps-4">
                <h5 class="info-text mb-0 fw-bold">Monday: 08:30am to 17:30pm </h5>
                <h5 class="info-text mb-0 fw-bold">Tuesday: 08:30am to 17:30pm</h5>
                <h5 class="info-text mb-0 fw-bold">Wednesday: 08:30am to 17:30pm</h5>
                <h5 class="info-text mb-0 fw-bold">Thursday: 08:30am to 17:30pm</h5>
                <h5 class="info-text mb-0 fw-bold">Friday: 08:30am to 17:30pm</h5>
                <h5 class="info-text mb-0 fw-bold">Saturday & Sunday: Closed</h5>


              </div>
            </div>
      </div>

  </div>
<section>
