import { Component, Input } from '@angular/core';
import { IGroupImage } from 'src/app/products/common/models/group-image';

@Component({
  selector: 'app-sliced-gallery',
  templateUrl: './sliced-gallery.component.html',
  styleUrls: ['./sliced-gallery.component.scss']
})
export class SlicedGalleryComponent {
// #region fields and properties
@Input() products: IGroupImage[]
// #endregion

constructor(){}

// #region helper methods

// #endregion

// #region interface implementation

// #endregion
}
