import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants';
import { Utils } from 'src/app/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private customersURL='customers/customermodone'
  // #region fields and properties

  isDarkTheme: boolean = false;
  // #endregion

  constructor(private _http: HttpClient,private _overlay: OverlayContainer){}

  // #region helper methods
  updateProfile = (model: any): Observable<any> =>
  this._http.post<any>((Constants.stsAuthority as string) + 'account/updateclaims', model).pipe(
    catchError(Utils.handleError)
  );

  toggleTheme(): void {
    if (this.isDarkTheme) {
      this._overlay.getContainerElement().classList.remove('dark-theme');
      this._overlay.getContainerElement().classList.add('light-theme');
    } else {
      this._overlay.getContainerElement().classList.add('light-theme');
    }
    if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
    } else if (document.body.classList.contains('light-theme')) {
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.add('light-theme');
    }

    this.isDarkTheme = !this.isDarkTheme;
  }
  // #endregion

  // #region interface implementation

  // #endregion

  //adding user sign up form
   // adding new customer
   AddNewCustomer = (customer : any)=>
   this._http.post(Constants.apiRoot+this.customersURL, customer)
}
