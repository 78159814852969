import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { bounceAnimation } from '../../animations/app-animation';

@Component({
  selector: 'app-animated-button',
  templateUrl: './animated-button.component.html',
  styleUrls: ['./animated-button.component.scss'],
  animations: [bounceAnimation]
})
export class AnimatedButtonComponent {

  // #region fields and properties
  state: string = 'out'

  @Input() color: string
  @Input() content: string
  @Input() icon: string
  @Input() alignRight: boolean = false
  @Input() alignLeft: boolean = false
  @Input() id: string
  @Input() isFab: boolean = false

  @Output() btnClick: EventEmitter<string> =
              new EventEmitter<string>();
  // #endregion

  constructor(){}

  // #region helper methods
  notifyParent(){
    this.btnClick.emit(this.id);
  }
  // #endregion

  // #region interface implementation

  // #endregion

}
