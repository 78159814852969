export class Constants {
  //#region http
   //public static clientRoot = 'https://angprod10.uksouth.cloudapp.azure.com:6005/';
  //public static clientRoot = 'http://localhost:4200/';
  //public static clientRoot = 'https://angvm01.uksouth.cloudapp.azure.com:6014/';
  public static clientRoot = 'https://www.navsainternational.com/';

  //public static apiRoot = 'https://localhost:44379/api/'
  //public static apiRoot = 'https://angvm01.uksouth.cloudapp.azure.com:6016/api/';
	public static apiRoot = 'https://angprod10.uksouth.cloudapp.azure.com:6003/api/'
  //public static apiRoot = 'https://localhost:6001/api/';
  //public static stsAuthority = 'https://angvm01.uksouth.cloudapp.azure.com:6007';

  //public static stsAuthority = 'https://angvm01.uksouth.cloudapp.azure.com:6018';
 //public static stsAuthority = 'https://localhost:5001';
 //public static stsAuthority = 'https://angprod10.uksouth.cloudapp.azure.com:6007/';
 public static stsAuthority = 'https://www.navsainternational.com:6007/';

  //public static images = 'https://angvm01.uksouth.cloudapp.azure.com:6016/Images/'

   public static images = 'https://angprod10.uksouth.cloudapp.azure.com:6005/Images/'

	//public static clientId = 'EncoreIS-Navsa-App';
  public static clientId = 'EncoreIS-Customer-App';
  //#endregion

  //#region Style
  editorStyleMode = 'underlined'
  //#endregio0
}
