import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }
  
  ngOnInit(): void {
  }
  register() {
      window.open('https://angvm01.uksouth.cloudapp.azure.com:6007/Account/Register/', '_blank');
  
  }
}
