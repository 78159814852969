<div class="container">
  <div class="row">
    <div class="space3d">
      <div class="_3dbox" [@rotate]='cubeState'>
        <div class="_3dface _3dface--front" [ngStyle]="{'background-image': 'url(' + frontImage + ')'}"></div>
        <div class="_3dface _3dface--top" [ngStyle]="{'background-image': 'url(' + topImage + ')'}"></div>
        <div class="_3dface _3dface--bottom" [ngStyle]="{'background-image': 'url(' + bottomImage + ')'}"></div>
        <div class="_3dface _3dface--left" [ngStyle]="{'background-image': 'url(' + leftImage + ')'}"></div>
        <div class="_3dface _3dface--right" [ngStyle]="{'background-image': 'url(' + rightImage + ')'}"></div>
        <div class="_3dface _3dface--back" [ngStyle]="{'background-image': 'url(' + backImage + ')'}"></div>
      </div>
    </div>
  </div>
</div>
