import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { IProductImage } from 'src/app/products/common/models/product-image';
import { fadeinAnimation } from 'src/app/shared/animations/app-animation';

@Component({
  selector: 'app-card-gallery',
  templateUrl: './card-gallery.component.html',
  styleUrls: ['./card-gallery.component.scss'],
  animations:[fadeinAnimation]
})
export class CardGalleryComponent implements OnInit{

  // #region fields and properties
  maxHeight: string = '650px;'
  @Input() images: IProductImage[] = [];
  @Input() selectedImage: IProductImage;
  state: boolean = false;
  // #endregion

  constructor( private _breakpointObserver: BreakpointObserver){}

  // #region helper methods

  // #endregion

  // #region interface implementation
  ngOnInit(): void {
    this._breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XSmall])
          this.maxHeight = '200px';
        if (state.breakpoints[Breakpoints.Small])
          this.maxHeight = '250px';
        if (state.breakpoints[Breakpoints.Medium])
          this.maxHeight = '500px';
        if (state.breakpoints[Breakpoints.Large])
          this.maxHeight = '550px';
        if (state.breakpoints[Breakpoints.XLarge])
          this.maxHeight = '650px';
      });
  }
  // #endregion

}
