import { Injectable } from '@angular/core';
import { UserManager, User } from 'oidc-client';
import { Constants } from '../../../shared/constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthContext } from '../models/auth.context';
import { UserProfile } from '../models/user.profile';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //#region Fields and properties
  private userManager: UserManager;
  private _user: User;
  private _loginChangedSubject = new Subject<boolean>();

  get getUser(): User {
    return this._user;
  }

  loginChanged = this._loginChangedSubject.asObservable();
  userProfile: any;
  userName: string;
  authContext: AuthContext;

  idAccountSubject = new BehaviorSubject<number>(0)
  idAccountAction$ = this.idAccountSubject.asObservable();
  //#endregion

  //#region Constructor
  constructor(private httpClient: HttpClient) {
    const stsSettings = {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
     // scope: 'openid profile EncoreApi',
      scope: 'openid profile',
      redirect_uri: `${Constants.clientRoot}signin-callback`,
      response_type: 'code',
      post_logout_redirect_uri: `${Constants.clientRoot}signout-callback`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${Constants.clientRoot}assets/silent-callback.html`
    };
    this.userManager = new UserManager(stsSettings);
    this.userManager.events.addAccessTokenExpired(_ => {
      this._loginChangedSubject.next(false);
    });
    let test = this._user;
    this.userManager.events.addUserLoaded(user => {
      if (this._user !== user) {
        this._user = user;
        this.loadSecurityContext();
        this._loginChangedSubject.next(!!user && !user.expired);
      }
    });

  }
  //#endregion

  //#region Helper Methods
  login() {
    return this.userManager.signinRedirect();
  }

  isLoggedIn(): Promise<boolean> {
    return this.userManager.getUser().then(user => {
      const currentUser = !!user && !user.expired;
      if (this._user !== user) {
        this._loginChangedSubject.next(currentUser);
      }
      if (currentUser && !this.authContext) {
        this.loadSecurityContext();
      }
      this._user = user;
      return currentUser;
    });
  }

  completeLogin() {
    return this.userManager.signinRedirectCallback().then(user => {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);
      return user;
    }).catch((err) => {});
  }

  logout() {
    this.userManager.signoutRedirect();
  }

  completeLogout() {
    this._user = null;
    this._loginChangedSubject.next(false);
    return this.userManager.signoutRedirectCallback();
  }

  getAccessToken() {
    return this.userManager.getUser().then(user => {
      if (!!user && !user.expired) {
        return user.access_token;
      }
      else {
        return null;
      }
    });
  }

  loadSecurityContext() {
    if(!this._user?.profile?.email)
      return;

    this.httpClient
      .get<UserProfile>(`${Constants.apiRoot}customers/contact/${this._user?.profile?.email}`)
      .subscribe(
        context => {
          this.authContext = new AuthContext();
          // this.authContext.claims = context.claims;
          this.authContext.userProfile = context;

          this.idAccountSubject.next(context?.idAccount?? 0)
          //this.userName = context.claims.find(c => c.claimType == "given_name").claimValue;
        },
        error => console.error(error)
      );
  }
  //#endregion
}
