import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthService } from '../admin/common/services/auth.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Constants } from './constants';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.startsWith(Constants.apiRoot)) {
        return from(this.authService.getAccessToken().then(token => {
          const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
          const authReq = req.clone({ headers });
          return next.handle(authReq).pipe(tap(_ => { }, error => {
            var respError = error as HttpErrorResponse;
            if (respError && (respError.status === 401 || respError.status === 403)) {
              this.router.navigate(['/unauthorized']);
            }
          })).toPromise();
        }));
      }
      else {
        return next.handle(req);
      }
  }

}
