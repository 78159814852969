<div class="gallery">
  <div class="row">
    <div class="col-12">
      <img [@fadein]="selectedImage" class="img-fluid gallery__img" [style.max-height]="maxHeight" [src]="selectedImage">
    </div>
  </div>
  <div class="row twenty">
    <div class="col-12">
      <mat-radio-group class="radio-group" [(ngModel)]="selectedImage">
        <mat-label *ngFor="let image of images; let i = index">
          <mat-radio-button [value]="'https://angprod10.uksouth.cloudapp.azure.com:6005/Images/' + image.imageFileName" [checked]="state != state">
            <img class="img-fluid gallery__thumb" [src]="'https://angprod10.uksouth.cloudapp.azure.com:6005/Images/' + image.imageFileName">
          </mat-radio-button>
        </mat-label>
      </mat-radio-group>
    </div>
  </div>
</div>
